import React, { useState } from "react";
import { useEffect } from "react";
import HomeHeader from "../../CustomComponents/HomeHeader";
import wp1867328 from "../../Assets/Images/wp1867328.png";
import AddNewPaymentMethod from "./InvestPopup";

function Descriptions(props) {
  const [tab,setTab]=useState(1)
  const [paymentPopup, setPaymentPopup] = useState(false);

  useEffect(() => {
    console.log('dzkdkjnsjkdhds',props);
  }, []);
  let item=props?.location?.state?.item
  return (
    <div>
      {/* <HomeHeader></HomeHeader> */}
      <div className="inversContainer">
      <div style={{ position: "absolute", top: 0, width: "100%",backgroundColor:'rgb(6,8,31)' }}>
            <HomeHeader></HomeHeader>
          </div>
          <div style={{height: "100vh",}}>

        <img style={{ width: "100vw", height: "100%" }} src={props?.location?.state?.item?.backdrop_path?props?.location?.state?.item?.backdrop_path:props?.location?.state?.item?.imgUrl} />
         <div style={{ position: "absolute", top: "100%", width: "100%" }}>
            <button
              onClick={()=>{
                setPaymentPopup(true)
              }}
              type="submit"
              class="button-contact common_btn btnabs"
            >
              {"Invest"}
            </button>
          </div>
          </div>

        {/* <button type="submit" class="button-contact common_btn btnabs">
          {"Invest"}
        </button> */}
        
         
      </div>
      <section class="pt-3 welcome_bg sliders">
        <div class="container">
          <div class="row">
            <div class="col-md-1"></div>
            <div onClick={()=>{setTab(1)}} style={{width:'15%'}} class="col-md-2 tab-bg tab-data text-center text-white ">
              {/* <a class="text-white" href=""> */}
                Info
              {/* </a> */}
            </div>
            <div onClick={()=>{setTab(2)}} style={{width:'25%'}} class="col-md-2 tab-data text text-center text-white">
              {/* <a class="text-white" href=""> */}
              Financials
              {/* </a> */}
            </div>
            <div onClick={()=>{setTab(3)}} style={{width:'24%'}} class="col-md-2 tab-data text-center text-white">
              {/* <a class="text-white" href=""> */}
                Graphics
              {/* </a> */}
            </div>
            <div onClick={()=>{setTab(4)}} style={{width:'16%'}} class="col-md-2 tab-data text-center text-white">
              {/* <a class="text-white" href=""> */}
                Perks
              {/* </a> */}
            </div>
            <div onClick={()=>{setTab(5)}} style={{width:'20%'}} class="col-md-2 tab-bg-1 tab-data text-center text-white">
              {/* <a class="text-white" href=""> */}
                Extras
              {/* </a> */}
            </div>
            <div class="col-md-1"></div>
          </div>

          <div class="row">
            <div class="col-md-12"></div>
          </div>
        </div>
        <div class="container mt-5">
          <div class="bg-wrapper">
            <div class="row">
              <div class="col-12">
                <h3 class="title-text">Description</h3>
              </div>
              <div class="col-12">
                <p class="subtitle-text">
                  {item?.description}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="container mt-5">
          <div class="bg-wrapper">
            <div class="row">
              <div class="col-md-2">
                <figure class="figure">
                  <img
                    src={require("./Mask Group 12.png").default}
                    class="figure-img img-fluid shape-round"
                    alt="..."
                  />
                 
                </figure>
              </div>
              <div class="col-md-2">
                <figure class="figure">
                  <img
                    src={require("./Mask Group 13.png").default}
                    class="figure-img img-fluid shape-round"
                    alt="..."
                  />
                
                </figure>
              </div>
              <div class="col-md-2">
                <figure class="figure">
                  <img
                    src={require("./Mask Group 13.png").default}
                    class="figure-img img-fluid shape-round"
                    alt="..."
                  />
                 
                </figure>
              </div>
              <div class="col-md-2">
                <figure class="figure">
                  <img
                    src={require("./Mask Group 13.png").default}
                    class="figure-img img-fluid shape-round"
                    alt="..."
                  />
                
                </figure>
              </div>
              <div class="col-md-2">
                <figure class="figure">
                  <img
                    src={require("./Mask Group 13.png").default}
                    class="figure-img img-fluid shape-round"
                    alt="..."
                  />
                  <figcaption class="figure-caption text-center">
                    Chad Stahels <br />
                    <span class="desigination">(Director)</span>
                  </figcaption>
                </figure>
              </div>
              <div class="col-md-2">
                <figure class="figure">
                  <img
                    src={require("./Mask Group 13.png").default}
                    class="figure-img img-fluid shape-round"
                    alt="..."
                  />
                 
                </figure>
              </div>
            </div>
          </div>
        </div> */}
        {
          tab===1?
          <div class="container mt-5 px-5">
          <div class="row">
            <div class="col-md-12 text-center">
              <h3 class="production-text text-center"> Info</h3>
            </div>
            <div class="col-md-12">
              <p class="production-text">Production House -{item?.production_house}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Estimated budget -{item?.estimated_budget}</p>
            </div>
            <div class="col-md-12 text-center">
              <h3 class="production-text text-center">
                {" "}
                Expected time of completion
              </h3>
            </div>
            <div class="col-md-12">
              <p class="production-text"> Pre production-{item?.pre_production}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Production-{item?.production}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Post production-{item?.post_production}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Format-{item?.format}</p>
              <p class="production-text">Genre-{item?.genre}</p>
            </div>
          </div>
        </div>
      :
      tab==2?
      <div class="container mt-5 px-5">
          <div class="row">
            <div style={{}} class="col-md-12 text-center">
              <h3  class="production-text text-center"> Financials</h3>
            </div>
            <div class="col-md-12">
              <p class="production-text">Total estimated budget -{item?.total_estimated_budget}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Estimated profit -{item?.estimated_profit}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Estimated payout date -{item?.estimated_payout_date}</p>
            </div>
            <div class="col-md-12 text-center">
              <h3 class="production-text text-center">
                {" "}
                Pre-production 
              </h3>
            </div>
            <div class="col-md-12">
              <p class="production-text">Signing in bonus-{item?.pre_production_signing_bonus}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Script writing-{item?.pre_production_script_writing}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Miscellaneous-{item?.pre_production_miscellaneous}</p>
            </div>
            <div class="col-md-12 text-center">
              <h3 class="production-text text-center">
                {" "}
                Production
              </h3>
            </div>
            <div class="col-md-12">
              <p class="production-text">Actors-{item?.production_actors}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Crew member-{item?.production_crew_member}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Supporting actor-{item?.production_supporting_actor}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Director-{item?.production_director}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Equipments-{item?.production_equipments}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Miscellaneous-{item?.production_miscellaneous}</p>
            </div>
            <div class="col-md-12 text-center">
              <h3 class="production-text text-center">
                {" "}
                Post production
              </h3>
            </div>
            <div class="col-md-12">
              <p class="production-text">Editing-{item?.post_production_editing}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Marketing-{item?.post_production_marketing}</p>
            </div>
            <div class="col-md-12">
              <p class="production-text">Miscellaneous-{item?.post_production_miscellaneous}</p>
            </div>
           
           
          </div>
        </div>  :
        tab===4?
        <div class="container mt-5 px-5">
        <div class="row">
          <div class="col-md-12 text-center">
            <h3 class="production-text text-center"> Perks</h3>
          </div>
          <div class="col-md-12">
            <p class="production-text">{item?.parks}</p>
          </div>
          
          
        </div>
      </div>
        :
        tab===3?
        <div class="container mt-5 px-5">
        <div class="row">
          <div class="col-md-12 text-center">
            <h3 class="production-text text-center">Graphics</h3>
          </div>
          
        </div>
      </div>
        :
        <div class="container mt-5 px-5">
        <div class="row">
          <div class="col-md-12 text-center">
            <h3 class="production-text text-center">Extras</h3>
          </div>
          
        </div>
      </div>
      }
      </section>

      <AddNewPaymentMethod
        show={paymentPopup}
        toggle={() => setPaymentPopup(!paymentPopup)}
      />
    </div>
  );
}

export default Descriptions;
